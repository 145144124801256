import ls from 'local-storage';

export function getAuthToken() {
    return ls('lycan-auth-token');
  } 

export function setAuthToken(token) {
    return ls('lycan-auth-token', token);
}
export function setAuthRole(role) {
    return ls('lycan-auth-role', role);
}

export function clearAuthToken() {
    ls.remove('lycan-auth-user');
    return ls.remove('lycan-auth-token');
}

export function setFilters(filter) {    
    return ls('filters-admin', filter);

}

export function setAuthUser(user) {
    return ls('lycan-auth-user', user);
}

export function isAuthenticated() {
    return getAuthToken();
}

export function getAuthUser() {
    try {
        return JSON.parse(ls('lycan-auth-user'));
    } catch (e) {
        return null;
    }
}